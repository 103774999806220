import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import "./styles.css";

export default function App() {
  const [shadows, setShadows] = useState([]);
  const titleRef = useRef(null);

  useEffect(() => {
    setShadows([]);
    let count = 0;
    let step = 4;
    let x = 0;
    let y = 0;
    const directions = [
      [1, 1],
      [-1, 1],
      [-1, -1],
      [1, -1]
    ];
    let currentDirection = 0;

    let setNextPoint = () => {
      let titleHeight = titleRef.current.clientHeight;
      let titleWidth = titleRef.current.clientWidth;
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      let startX = Math.round((screenWidth - titleWidth) / 2);
      let startY = Math.round((screenHeight - titleHeight) / 2);

      let [currX, currY] = directions[currentDirection];
      for (let i = currentDirection; i < currentDirection + 4; i++) {
        let modulI = i % 4;
        let [dirX, dirY] = directions[modulI];
        let nextX = startX + x + step * dirX;
        let nextY = startY + y + step * dirY;

        if (nextX < 0 || nextY < 0) continue;
        if (
          nextX + titleWidth > screenWidth ||
          nextY + titleHeight > screenHeight
        )
          continue;
        if (currX !== dirX && currY !== dirY) continue;

        currentDirection = modulI;

        x = x + dirX * step;
        y = y + dirY * step;
        return;
      }
    };

    let frame = 0;
    let onFrame = () => {
      if (frame % 6 === 0) {
        count++;
        let color = count % 2 === 0 ? "black" : "yellow";
        setNextPoint();
        setShadows((current) => {
          return current.concat(`${x}px ${y}px 0 ${color}`).slice(-25);
        });
      }
      frame++;
      animationFrame = requestAnimationFrame(onFrame);
    };

    let animationFrame = requestAnimationFrame(onFrame);
    return () => cancelAnimationFrame(animationFrame);
  }, [window.innerWidth]);

  const currentOffset = useMemo(() => {
    const [first] = shadows;
    if (shadows.length <= 1 || !first)
      return { "--offset-x": "4px", "--offset-y": "4px" };

    const [, x, y] = /^(-?\d+px) (-?\d+px)/g.exec(`${first}`) ?? [
      undefined,
      "4px",
      "4px"
    ];
    return { "--offset-x": x, "--offset-y": y };
  }, [shadows]);

  return (
    <>
      <Title
        ref={titleRef}
        style={{
          textShadow: shadows.join(", ")
        }}
      >
        klemen.dev
      </Title>
      <Title className="front" style={currentOffset}>
        klemen.dev
      </Title>
    </>
  );
}

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 900;
  font-family: sans-serif;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(
    calc(-50% + var(--offset-x)),
    calc(-50% + var(--offset-y)),
    0
  );
`;
